import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import NavigationBar from './components/NavigationBar';
import Home from './pages/Home';
import AddOrEditPurchase from './pages/AddOrEditPurchase';
import AddOrEditProduct from './pages/AddOrEditProduct';
import Purchases from './pages/Purchases';
import Products from './pages/Products';
import Waitlist from './pages/Waitlist';
import ProductWaitlist from './pages/ProductWaitlist';
import { AuthContext } from './contexts/AuthContext';
import Settings from './pages/Settings';

function App() {
  const { isAuthenticated, validateToken } = useContext(AuthContext);
  const navigate = useNavigate();

  // Effect to validate the token whenever isAuthenticated changes
  useEffect(() => {
    if (!validateToken()) {
      console.log ('Invalid Token!');
      navigate('/'); // Redirect to home if the token is invalid
    }
  }, [isAuthenticated, navigate, validateToken]);

  return (
    <div className="App container" data-bs-theme="dark">
      <NavigationBar />
      <Routes>
        <Route path="/" element={<Home />} />
        {isAuthenticated ? (
          <>
            <Route path="/purchases" element={<Purchases />} />
            <Route path="/add-purchase" element={<AddOrEditPurchase isEdit={false} />} />
            <Route path="/edit-purchase/:id" element={<AddOrEditPurchase isEdit={true} />} />
            <Route path="/products" element={<Products />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route path="/waitlist/:product_id" element={<ProductWaitlist />} />
            <Route path="/add-product" element={<AddOrEditProduct isEdit={false} />} />
            <Route path="/edit-product/:id" element={<AddOrEditProduct isEdit={true} />} />
            <Route path="/settings" element={<Settings />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" replace />} /> // Redirect to home if not authenticated
        )}
      </Routes>
    </div>
  );
}

export default App;
