import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

function AddOrEditProduct({ isEdit }) {
  const { id } = useParams();
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    images: [''],
    inventory: 0,
    priority: 0,
    type: 'product' // Default value for the type
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isEdit && id && token) {
      fetchProduct(id);
    }
  }, [isEdit, id, token]);

  const fetchProduct = async (productId) => {
    setLoading(true);
    setError(null);

    console.log('Product API token: ' + token);

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/users/products/${productId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching product.');
      }
      const productData = await response.json();
      console.log('Product Data: ' + JSON.stringify(productData));
      setProduct(productData);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSaveProduct = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const method = isEdit ? 'PUT' : 'POST';
      const endpoint = isEdit ? `${STORE_BACKEND_URL}/users/products/${id}` : `${STORE_BACKEND_URL}/users/products`;
      const response = await fetch(endpoint, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...product,
          inventory: Number(product.inventory) // Ensure inventory is sent as a number
        })
      });

      if (!response.ok) {
        throw new Error('Error saving product.');
      }

      toast.success('Product saved successfully!');
      navigate('/products');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <h2>{isEdit ? 'Edit Product' : 'Add Product'}</h2>
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <form onSubmit={handleSaveProduct}>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            className="form-control"
            value={product.name || ''}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Description</label>
          <textarea
            name="description"
            className="form-control"
            value={product.description || ''}
            onChange={handleInputChange}
            required
            rows="6"
          />
        </div>
        <div className="form-group">
          <label>Price (in cents)</label>
          <input
            type="number"
            name="price"
            className="form-control"
            value={product.price || ''}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Category</label>
          <input
            type="text"
            name="category"
            className="form-control"
            value={product.category || ''}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Type</label>
          <select
            name="type"
            className="form-control"
            value={product.type || 'product'}
            onChange={handleInputChange}
            required
          >
            <option value="affiliate">Affiliate</option>
            <option value="product">Product</option>
            <option value="part">Part</option>
          </select>
        </div>
        <div className="form-group">
          <label>Images</label>
          <input
            type="text"
            name="images"
            className="form-control"
            value={(product.images && product.images[0]) || ''}
            onChange={(e) =>
              handleInputChange({
                target: { name: 'images', value: [e.target.value] }
              })
            }
            required
          />
        </div>
        <div className="form-group">
          <label>Inventory</label>
          <input
            type="number"
            name="inventory"
            className="form-control"
            value={product.inventory || 0}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Priority</label>
          <input
            type="number"
            name="priority"
            className="form-control"
            value={product.priority || 0}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">{isEdit ? 'Update Product' : 'Add Product'}</button>
      </form>
    </div>
  );
}

export default AddOrEditProduct;
