import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Add any home-specific styles here
import { AuthContext } from '../contexts/AuthContext';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

const Home = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <div className="home-container">
      <h1>Sunthar's Super Admin</h1>
      <p>
        This is your one-stop dashboard to manage the store. 
      </p>
      {isAuthenticated ? (
        <div>
          <Link to="/purchases">
            Purchases
          </Link>
        </div>
      ) : (
        <Link to={`${STORE_BACKEND_URL}/auth/google`}>
            Login with Google
        </Link>
      )}
    </div>
  );
};

export default Home;