import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../contexts/AuthContext';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

function Waitlist() {
  const { token } = useContext(AuthContext);
  const [waitlist, setWaitlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error] = useState(null);


  useEffect(() => {
    if (token) {
      handleFetchWaitlist();
    } else {
      console.log('Token is not available');
    }
  }, [token]);

  const handleFetchWaitlist = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/waitlist`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching waitlist.');
      }

      const waitlistData = await response.json();
      setWaitlist(waitlistData);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNotifyUsers = async (productId) => {
    try {
      const response = await fetch(`${STORE_BACKEND_URL}/waitlist/notify/${productId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      // Check if the response is not OK
      if (!response.ok) {
        const errorData = await response.json(); // Parse the error message from the response
        const errorMessage = errorData.message || 'Error sending notifications'; // Use the error message from the backend or a fallback message
        //toast.error(errorMessage);
        throw new Error(errorMessage);
      } else {
        // Parse the response as JSON
        const data = await response.json();
  
        // Show the message from the response
        toast.success(data.message || 'Notification sent successfully!');
        handleFetchWaitlist();
      }
    } catch (error) {
      // Show the error message in the toast
      toast.error(error.message);
    }
  };

  return (
    <div className="theme-container">
      <ToastContainer />
      <h2>Waitlist</h2>
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {!loading && !error && waitlist.length > 0 && (
        <div className="row">
          {waitlist.map((product) => (
            <div className="col-md-12" key={product.productId}>
              <div className="card mb-12" style={{ marginBottom: '10px' }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-8" style={{ textAlign: 'left' }}>
                      <h5>{product.productName}</h5>
                      <p>
                        Total Users on Waitlist: {product.totalUsers} <br />
                        Users unsubscribed from Waitlist: {product.notifiedUsers}
                      </p>
                    </div>
                    <div className="col-md-4" style={{ textAlign: 'right' }}>
                      <button 
                        onClick={() => handleNotifyUsers(product.productId)} 
                        className="btn btn-primary"
                      >
                        Notify Users
                      </button>
                      <Link 
                        to={`/waitlist/${product.productId}`}
                        state={{ productName: product.productName }} // Pass state directly
                        className="btn btn-primary"
                      >
                        View Waitlist
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Waitlist;
