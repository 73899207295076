import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './Products.css';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from '../contexts/AuthContext';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

// Utility function to format price from cents to dollars
const formatPrice = (cents) => {
  if (cents) {
    return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  } else {
    return "";
  }
};

function Products() {
  const { token } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (token) {
      console.log('Token:', token); // Debugging statement
      handleFetchProducts();
    } else {
      console.log('Token is not available'); // Debugging statement
    }
  }, [token]);

  const handleFetchProducts = async () => {
    setLoading(true);
    setError(null);

    if (token) {
      try {
        const response = await fetch(`${STORE_BACKEND_URL}/users/products`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Error fetching products.');
        }
        const products = await response.json();
        setProducts(products);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteProduct = async (productId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/products/${productId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error deleting product.');
      }

      toast.success('Product deleted successfully!');
      handleFetchProducts();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="theme-container">
      <ToastContainer />
      <h2>Products</h2>
      <div>
        <Link to="/add-product">
          <h6>Add Product</h6>
        </Link>
      </div>
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {!loading && !error && products.length > 0 && (
        <div className="row">
          {products.map((product) => (
            <div className="col-md-12" key={product.id}>
              <div className="card mb-12" style={{ marginBottom: '10px' }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4" style={{ textAlign: 'left' }}>
                      {product.images && product.images.length > 0 && (
                        <img src={product.images[0]} alt={product.name} style={{ width: '150px', height: '150px', objectFit: 'cover' }} />
                      )}
                      <p>
                      <Link to={`/edit-product/${product.id}`}>Edit</Link>
                      </p>
                    </div>
                    <div className="col-md-8" style={{ textAlign: 'left' }}>
                      <h6 className="card-title">{product.description}</h6>
                      <p>
                        {formatPrice(product.price)}  <br />
                        {product.category} <br />
                        Inventory: {product.inventory}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Products;
