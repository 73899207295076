import React, { useContext, useRef, useEffect,useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FaHome, FaBoxOpen, FaShoppingCart, FaCogs } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './NavigationBar.css'; // Add any specific styles if needed
import { AuthContext } from '../contexts/AuthContext';

// With React you have to prefix the environment variable with REACT_APP_
const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

const NavigationBar = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      closeNavbar();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Navbar ref={navbarRef} bg="dark" variant="dark" expand="lg" expanded={isOpen}>
        <Navbar.Brand as={Link} to="/">
          <img
              className="nav-logo"
              src="/logo.png"
              alt="Sunthar's Super Store"
          />
        </Navbar.Brand>
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav"
          onClick={toggleNavbar}
          className={isOpen ? 'navbar-toggler open' : 'navbar-toggler'}
        >
          <div className="hamburger-icon">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          {isAuthenticated ? (
          <Nav className="ms-auto" onClick={closeNavbar}>
            <Nav.Link as={Link} to="/">
              <FaHome style={{ marginRight: '5px' }} />
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/products">
              <FaBoxOpen style={{ marginRight: '5px' }} />
              Products
            </Nav.Link>
            <Nav.Link as={Link} to="/waitlist">
              <FaBoxOpen style={{ marginRight: '5px' }} />
              Waitlist
            </Nav.Link>
            <Nav.Link as={Link} to="/purchases">
              <FaShoppingCart style={{ marginRight: '5px' }} />
              Purchases
            </Nav.Link>
            <Nav.Link as={Link} to="/purchases">
              <FaShoppingCart style={{ marginRight: '5px' }} />
              Services
            </Nav.Link>
            <Nav.Link as={Link} to="/settings">
              <FaCogs style={{ marginRight: '5px' }} />
              Settings
            </Nav.Link>
            <Nav.Link href={`${STORE_BACKEND_URL}/auth/logout`}>
              Logout
            </Nav.Link>
          </Nav>
          ) : (
          <Nav className="ms-auto" onClick={closeNavbar}>
            <Nav.Link href={`${STORE_BACKEND_URL}/auth/google`}>
              Login with Google
            </Nav.Link>
          </Nav>
          )}
        </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;