import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';  // Correct import

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

export const AuthContext = createContext();

// Function to check if the token is expired
const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp < currentTime; // Check if token is expired
  } catch (error) {
    console.error('Token decoding failed', error);
    return true; // If there's an error, consider the token expired
  }
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  // Function to validate the token
  const validateToken = useCallback(() => {
    if (!token || isTokenExpired(token)) {
      setIsAuthenticated(false);
      setToken(null);
      return false;
    }
    return true;
  }, [token]);

  // Check authentication status when the component mounts
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(`${STORE_BACKEND_URL}/auth/status`, {
          credentials: 'include',
        });
        const data = await response.json();
        setIsAuthenticated(data.isAuthenticated);
        if (data.isAuthenticated) {
          console.log('Authenticated and token is set: ' + data.token);
          setToken(data.token); // Ensure token is set
        }
      } catch (error) {
        console.error('Failed to check authentication status', error);
        setIsAuthenticated(false);
        setToken(null); // Ensure token is cleared
      }
    };

    checkAuthStatus();
  }, []);

  // Use effect to validate token on token change
  useEffect(() => {
    if (!validateToken()) {
      navigate('/'); // Redirect to home if the token is invalid
    }
  }, [validateToken, navigate]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, validateToken }}>
      {children}
    </AuthContext.Provider>
  );
};
