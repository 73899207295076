import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const STORE_BACKEND_URL = process.env.REACT_APP_STORE_BACKEND_URL;

function Settings() {
  const { token } = useContext(AuthContext);
  const [shipping, setShipping] = useState({ height: '', width: '', length: '', weight: '' });
  const [otherSetting, setOtherSetting] = useState(''); // Example of another setting
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (token) {
      fetchSettings();
    }
  }, [token]);

  const fetchSettings = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/settings`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Error fetching settings.');
      }

      const data = await response.json();
      setShipping(data.shipping || { height: '', width: '', length: '', weight: '' });
      setOtherSetting(data.otherSetting || ''); // Adjust based on actual setting structure
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setShipping({ ...shipping, [name]: value });
  };

  const handleOtherSettingChange = (e) => {
    setOtherSetting(e.target.value);
  };

  const handleSaveSettings = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const settingsToSave = {
      shipping,
      otherSetting // Include other settings as needed
    };

    try {
      const response = await fetch(`${STORE_BACKEND_URL}/settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(settingsToSave),
      });

      if (!response.ok) {
        throw new Error('Error saving settings.');
      }

      toast.success('Settings updated successfully!');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <ToastContainer />
      <h2>Settings</h2>
      <h4>Shipping</h4>
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <form onSubmit={handleSaveSettings}>
        <div className="form-group">
          <label>Height (in):</label>
          <input type="number" name="height" className="form-control" value={shipping.height} onChange={handleShippingChange} required />
        </div>
        <div className="form-group">
          <label>Width (in):</label>
          <input type="number" name="width" className="form-control" value={shipping.width} onChange={handleShippingChange} required />
        </div>
        <div className="form-group">
          <label>Length (in):</label>
          <input type="number" name="length" className="form-control" value={shipping.length} onChange={handleShippingChange} required />
        </div>
        <div className="form-group">
          <label>Weight (g):</label>
          <input type="number" name="weight" className="form-control" value={shipping.weight} onChange={handleShippingChange} required />
        </div>

        <h4>API Keys</h4>
        <div className="form-group">
          <label>Example Setting:</label>
          <input type="text" name="otherSetting" className="form-control" value={otherSetting} onChange={handleOtherSettingChange} />
        </div>

        <button type="submit" className="btn btn-primary">Save Settings</button>
      </form>
    </div>
  );
}

export default Settings;
